<template>
  <div>
    <nurse-detail
      fetchClinicsBy="clinic"
      :canDelete="true"/>
  </div>
</template>

<script>

import nurseDetail from '../../components/nurses/nurseDetail.vue'

export default {
  components: {
      nurseDetail
  },
  data:()=>({
  })
}
</script>
